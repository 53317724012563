/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

h1, h2, h3, h4, h5, h6, label{
    color:black;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

img.car {
    height: 86px;
    border-radius: 8px;
    margin-right: 8px;
}

label{
    padding-left: 0 !important;
    padding-right: 0 !important;
}